var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    [
      _vm.loading
        ? _c("ct-centered-spinner")
        : _c(
            "div",
            { staticClass: "domains-monitoring-page" },
            [
              _c("monitoring-instructional-accordion", {
                attrs: {
                  id: "domain-collapse",
                  header: "Domain Monitoring",
                  "instructional-text": _vm.instructionalContent,
                },
              }),
              _c("monitoring-identity-table", {
                attrs: {
                  "table-data": _vm.domains,
                  "identity-type-string-singular": "Domain",
                  "identity-type-string-plural": "Domains",
                  "item-type": _vm.identityType,
                  "owned-data": _vm.formatDomainData,
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }