<template>
  <b-container>
    <ct-centered-spinner v-if="loading" />
    <div v-else class="domains-monitoring-page">
      <monitoring-instructional-accordion
        :id="'domain-collapse'"
        :header="'Domain Monitoring'"
        :instructional-text="instructionalContent"
      />
      <monitoring-identity-table 
        :table-data="domains" 
        identity-type-string-singular="Domain" 
        identity-type-string-plural="Domains" 
        :item-type="identityType" 
        :owned-data="formatDomainData"
      />
    </div>
  </b-container>
</template>

<script>
import MonitoringInstructionalAccordion from '@/components/Monitoring/MonitoringInstructionalAccordion.vue'
import MonitoringIdentityTable from "@/components/Monitoring/MonitoringIdentityTable.vue"
import { mapGetters, mapActions } from 'vuex'
import { MonitoringIdentityType } from '@/common/modules/constants'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

export default {
  name: "MonitoringDomainsPage",
  components: {
    MonitoringIdentityTable,
    CtCenteredSpinner,
    MonitoringInstructionalAccordion,
  },
  data() {
    return {
      domains: [],
      identityType: MonitoringIdentityType.DOMAIN,
      loading: false,
      instructionalContent: {
        sections: [
          {
            header: 'Set up a Domain Identity to monitor',
            generic: 'You have two options for adding a domain to monitor:',
            items: [
              "<strong>Select from registered domain:</strong> Click “Add Domain from your account” to see a list of domains you’ve registered with our organization. Selecting the preferred domain name from the list ensures you're monitoring the domains on record with us.",
              "<strong>Add external Domain:</strong> Click “Add external Domain” below and enter the name of the domain you want to monitor. This is useful if you have a specific domain in mind that you haven't registered with us yet.",
            ],
          },
        ],
      },
    }
  },
  computed: {
    ...mapGetters('account', ['actualAccountID']),
    ...mapGetters('monitoring', [
      'monitoringIdentitiesByType',
    ]),
    ...mapGetters('domains', [
      'domainsForAccount',
    ]),
    formatDomainData() {
      return this.domainsForAccount?.map(domain => ({ value: domain.domain_name, text: domain.domain_name }))
    },
  },
  async mounted() {
    this.loading = true
    await this.fetchDomainsForAccount()
    await this.fetchMonitoringIdentitiesByType({ accountID: this.actualAccountID, identityType: MonitoringIdentityType.DOMAIN })
    this.domains = await this.formatIdentities(this.monitoringIdentitiesByType[MonitoringIdentityType.DOMAIN]) 
    this.loading = false
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchMonitoringIdentitiesByType',
      'formatIdentities',
    ]),
    ...mapActions('domains', [
      'fetchDomainsForAccount',
    ]),
  },
}
</script>
